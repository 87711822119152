<template>
  <b-row>
    <b-col class="page-home">
      <b-row class="border-bottom sticky-top bg-white">
        <div class="home-header">
          <div class="home-header__home">
            <NSFWToggle class="home-header__toggle" @change="() => {}" />
          </div>

          <CLink to="/" class="home-header__title">
            <CIcon name="logo-black" class="home-header__icon" />
          </CLink>

          <div class="home-header__addition">
            <button
              type="button"
              @click="$router.push('/discover')"
              class="home-header__button"
            >
              <CIcon
                name="search"
                class="home-header__icon home-header__icon--search"
              />
            </button>

            <tippy
              interactive
              placement="top-center"
              distant="7"
              trigger="mouseenter"
              theme="red"
              arrow
              arrowType="round"
            >
              <template #trigger>
                <button class="home-header__button">
                  <CIcon
                    name="notification"
                    class="home-header__icon home-header__icon--notification"
                  />

                  <CIcon
                    name="notification-dot"
                    class="home-header__icon home-header__icon--notification-dot"
                    v-if="updates.notifications"
                  />
                </button>
              </template>

              <div
                role="button"
                class="notification"
                @click="$router.push('/notifications')"
              >
                <div class="notification__item">
                  <CIcon
                    name="like"
                    class="notification__icon notification__icon--like"
                    path="notifications"
                  />
                  <span class="notification__font notification__font--tooltip">
                    {{ userStat.likes }}
                  </span>
                </div>

                <div class="notification__item">
                  <CIcon
                    name="user"
                    class="notification__icon notification__icon--user"
                    path="notifications"
                  />
                  <span class="notification__font notification__font--tooltip">
                    {{ userStat.subscribes }}
                  </span>
                </div>

                <div class="notification__item">
                  <CIcon
                    name="repeat"
                    class="notification__icon notification__icon--user"
                    path="notifications"
                  />
                  <span class="notification__font notification__font--tooltip">
                   0
                  </span>
                </div>
              </div>
            </tippy>
          </div>
        </div>
      </b-row>

      <ui-posts />
    </b-col>
  </b-row>
</template>
<script>
import UiPosts from "@/features/ui/common/UiPosts.vue";
import CIcon from "@/features/ui/CIcon.vue";
import CLink from "@/features/ui/CLink.vue";

import { mapGetters } from "vuex";
import NSFWToggle from "@/features/containers/NSFWToggle.vue";
export default {
  name: "PageHome",
  components: {
    NSFWToggle,
    CLink,
    CIcon,
    UiPosts,
  },

  computed: {
    ...mapGetters({
      updates: 'updates',
    }),

    userStat() {
      return (
        this.updates || {
          notifications: 0,
          messages: 0,
          likes: 0,
          subscribes: 0
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page-home {
  @include hideScroll;
}

.home-header {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: em(5) em(15) em(5);

  &__toggle {
    font-size: 85%; // change button size
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: em(24);
    margin-right: em(13);
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__addition {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__font {
    &--home {
      color: $black;
      font-family: $font-default;
      font-style: normal;
      font-weight: 700;
      font-size: em(16);
      line-height: 1;
      text-transform: uppercase;
    }
  }

  &__icon {
    width: em(138);
    height: em(46);

    &--search {
      color: $app-gray-17;
      width: em(19);
      height: em(19);
    }

    &--notification {
      color: $app-gray-17;
      width: em(19);
      height: em(19);
    }

    &--notification-dot {
      position: absolute;
      top: 0;
      right: 0;
      color: $app-red;
      width: em(8);
      height: em(8);
    }
  }
}

.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: em(10) em(12);
  min-width: em(135);
  border-radius: em(10);
  background-color: $app-red;

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: em(24);
    margin-right: em(10);

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    margin-right: em(4);
    color: $white;

    &--like {
      width: em(11);
      min-width: em(11);
      height: em(10);
    }

    &--user {
      width: em(12);
      min-width: em(12);
      height: em(13);
    }

    &--repeat {
      width: em(15);
      min-width: em(15);
      height: em(15);
    }
  }

  &__font {
    &--tooltip {
      color: $white;
      font-family: $font-default;
      font-size: em(12);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>

<template>
  <InfinityScroll
    v-if="posts && posts.length"
    :items="posts"
    :has-more="true"
    @rich-end="loadMore"
    :is-grid="false"
  >
    <template #default="{ item: post }">
      <b-row>
        <AppPost :post="post" />
      </b-row>
    </template>
  </InfinityScroll>

  <div v-else class="ui-posts">
    <div class="ui-posts__title">
      <span class="ui-posts__font ui-posts__font--title">Posts will appear here</span>
    </div>
    <div class="ui-posts__title ui-posts__title--secondary">
      <span class="ui-posts__font ui-posts__font--secondary">Get started by following a Creator</span>
    </div>
  </div>
</template>

<script>
import AppPost from "@/features/containers/post/AppPost.vue";
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import { mapGetters, mapMutations } from "vuex";
import { postType } from "@/config/postsType";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import Post from "@/components/models/Post";
export default {
  name: 'UiPosts',
  mixins: [waitRequest, validationError],
  components: {
    InfinityScroll,
    AppPost,
  },
  data() {
    return {
      initialPage: 1,
      nextPage: 1,
    };
  },

  mounted() {
    this.initialLoadPost();
  },

  computed: {
    ...mapGetters({
      postHome: "postHome/postHome",
      postHomeMeta: "postHome/postHomeMeta",
    }),

    posts() {
      return this.postHome.map((post) => new Post(post)) || [];
    },
  },

  methods: {
    ...mapMutations({
      setPosts: "postHome/setPosts",
      updatePosts: "postHome/updatePosts",
      setMeta: "postHome/setMeta",
    }),

    loadPosts(successCallback) {
      if (this.requestInProgress) return;

      const url = new URL(`/posts`, process.env.VUE_APP_API_URL);

      url.searchParams.set("page", this.nextPage);
      url.searchParams.set("type", postType.posts);

      const requestUrl = url.pathname + url.search;

      this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    async initialLoadPost() {
      this.$showSpinner();

      this.loadPosts(this.setCurrentPosts);
    },

    loadMore() {
      this.nextPage = this.nextPage + 1;
      this.loadPosts(this.updateCurrentPosts);
    },

    setCurrentPosts(data) {
      const { current_page, data: posts } = data;

      this.setPosts({ posts });
      this.setMeta({ meta: data });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data) {
      const posts = data.data;

      this.updatePosts({ posts });
      this.setMeta({ meta: data });
    },

    loadPostError(errors) {
      console.log(errors);
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-posts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title {
    margin-top: 27px;

    &--secondary {
      margin-top: 11px;
    }
  }

  &__font {
    font-family: var(--font-family);
    text-align: center;

    &--title {
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      text-align: center;
      color: $black;
    }

    &--secondary {
      font-weight: 400;
      font-size: 14px;
      line-height: 129%;

      color: $app-font-color-gray-7;
    }
  }
}
</style>
